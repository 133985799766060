<template>
  <div class="campaign-ended-grandparent">
    <section class="campaign-ended">
      <div class="campaign-ended__textblock-parent">
        <h4 class="campaign-ended__text__main-paragraph">
          KAMPANJEN ER NÅ AVSLUTTET
        </h4>
        <h2>
          Kampanjen er dessverre over, men du kan sjekke ut andre kampanjer og
          nyheter på freia.no eller besøke oss på Facebook.
        </h2>

        <img
          src="@/assets/elements/hero_mobile.png"
          class="campaign-ended__mobile-image"
        />

        <div class="campaign-ended__button-parent">
          <a href="https://freia.no/" target="_blank">
            <ButtonComponent
              class="campaign-ended__button"
              theme="primary"
              label="FREIA.NO"
            ></ButtonComponent>
          </a>

          <a
            href="https://contactus.mdlzapps.com/freiano/contact/no-NO/"
            target="_blank"
          >
            <ButtonComponent
              class="campaign-ended__button"
              theme="primary"
              label="KONTAKT OSS"
            ></ButtonComponent>
          </a>
        </div>
      </div>

      <div class="campaign-ended__desktop-image-parent">
        <!-- <img
          src="@/assets/elements/signature.png"
          class="campaign-ended__signature"
        /> -->

        <img
          src="@/assets/elements/thankyou-pattern.png"
          class="campaign-ended__desktop-image"
        />
      </div>
    </section>
    <img class="campaign-ended__pattern" src="@/assets/elements/pattern.png" />
  </div>
</template>
<script>
import Error from "@/components/Error.vue";
import { ButtonComponent } from "vue-elder-button";

export default {
  components: {
    Error,
    ButtonComponent,
  },
};
</script>

<style lang="scss">
.campaign-ended-grandparent {
  min-height: 100vh;
  padding-bottom: 1rem;
  overflow: hidden;
}

.campaign-ended {
  display: flex;

  margin-top: 14rem;

  @media (max-width: $mobile) {
    margin-top: 9rem;
    overflow: hidden;
  }

  &__textblock-parent {
    display: flex;
    flex-direction: column;

    text-align: center;
    width: 50%;

    margin-left: 3.5rem;
    margin-top: 5rem;

    @media (max-width: $tablet) {
      width: 80%;
      margin: auto;

      h3 {
        padding: 0rem 1rem;
      }
    }

    h4 {
      width: 80%;
      margin: auto;
      margin-bottom: 2rem;
    }

    h2 {
      width: 80%;
      margin: auto;
    }
  }

  &__mobile-image {
    @media (max-width: $mobile) {
      width: 175%;
      margin-left: -34%;
    }

    margin: auto;
    width: 80%;
    margin-top: 2rem;

    @media (min-width: $tablet) {
      display: none;
    }
  }

  &__designet-av {
    width: 290px;
    margin: auto;
    margin-top: 1.5rem;

    @media (max-width: $tablet) {
      width: 214px;
    }
  }

  &__desktop-image-parent {
    width: 50%;
    position: relative;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__desktop-image {
    position: absolute;
    top: -150px;
    right: 0px;
    width: 90%;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__signature {
    position: absolute;

    width: 20%;
    top: 100%;
    left: 0%;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__button {
    margin: auto;
    margin-top: 2rem;
  }

  &__button-parent {
    margin-top: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__paragraph {
      font-size: 1.5rem;
      font-weight: 600;
    }

    @media (max-width: $mobile) {
      gap: 0px;
    }
  }

  &__pattern {
    position: absolute;
    left: 0px;
    bottom: 225px;

    width: 175px;

    @media (max-width: $tablet) {
      display: none;
    }
  }
}
</style>
